@import "../../styles/colors";

.ed-tabela {
  background-color: white;
  color: #757575;

  td {
    padding: 1rem;
    // min-width: 3rem;
  }

  td.texto {
    max-width: 10rem;
  }

  th {
    border-top: none !important;
    // padding: 0;
  }

  .ed-tabela__sort__icon {
    display: none;
    svg {
      transition: transform 0.3s;
    }
    svg.rotate {
      transform: rotate(-180deg);
    }
  }

  .ed-tabela__sort__icon.active {
    display: inline;
  }

  .ed-tabela__sort:hover .ed-tabela__sort__icon {
    display: inline;
  }

  svg {
    color: $blue;
  }

  .circulo {
    border-radius: 50%;
    color: white;
    padding: 3px;
  }

  .circulo.ativo {
    background-color: $green;
  }

  .circulo.inativo {
    background-color: $red;
  }

  img {
    max-height: 4rem;
  }

  .center {
    text-align: center;
    vertical-align: middle;
  }

  .action {
    width: 6.5rem;
  }

  p {
    margin: 0;
    font-size: 0.8rem;
    color: $graylight;
  }

  @at-root.table-bordered tr {
    td:first-child,
    th:first-child {
      border-left: none;
    }
    td:last-child,
    th:last-child {
      border-right: none;
    }
  }
}

.ed-tabela__acoes {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  align-items: flex-end;
  justify-content: space-between;
  overflow-x: auto;
  padding: 0.25rem 0;

  svg {
    color: $blue;
  }

  .ed-tabela__pesquisa {
    display: flex;
    align-items: center;
    input {
      min-width: 7rem;
    }
  }

  .ed-tabela__filtros {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    > * {
      
      margin-top: 1rem;
    }
    .ed-tabela__datas {
      display: flex;
      margin-right: 1rem;
      align-items: flex-end;
      > *:not(:first-child) {
        margin-left: 1rem;
      }
      .data {
        width: 7.5rem;
      }
    }

    .ed-tabela__status {
      margin-right: 1rem;

      select {
        min-width: 6.25rem;
      }
    }
    p {
      margin: 0;
      color: $graylight;
    }
    .add-button {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        height: 38px;
        width: 38px;
      }
    }
  }
  

  
}

.ed-tabela__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    width: 3rem;
    text-align: center;
  }
}

.clickable {
  cursor: pointer;
}

.ed-tabela__notFound {
  text-align: center;
  padding: 2rem;
}

@media screen and(max-width: 992px) {
  .ed-tabela__acoes {
    margin-bottom: 1rem;
  }
  .ed-tabela {
    .hide-mobile {
      display: none;
    }
    img {
      max-height: 3rem;
      max-width: 100%;
    }
    td {
      text-overflow: ellipsis;
      max-width: 8rem;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
