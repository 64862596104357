div.big-card {
  padding: 0;
  color: #69707a;
  border-radius: 25px;
}

div.big-card .card-header {
  padding: 20px;
  color: #0061f2;
}

div.big-card .card-body {
  padding: 2rem 3.5rem;
}

div.big-card input {
  color: #52575f;
}

@media screen and(max-width: 992px) {
  div.big-card .card-body {
    padding: 1rem 1.5rem;
  }
}