.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.invalid {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-field .tox-tinymce {
  border-color: #dc3545;
}

.invalid-field .dropzone {
  border-color: #dc3545 !important;
}

.modalAviso .modal-header {
  background-color: #ffc107!important;
  color: white;
}

.modalErro .modal-header {
  background-color: #dc3545!important;
  color: white;
}

.modalFb .modal-content{
  border: none;
}

.modalFb .modal-header .modal-title {
  display: flex;
  align-items: center;
}

.modalFb .modal-header .modal-title svg {
  margin-right: 10px;
}

.title-control {
  display: flex;
  align-items: center;
}

.title-control svg {
  margin-left: 10px;
  color: #007bff;
}

.add_widget {
  width: 100%;
}

.form-group {
  scroll-margin: 100px 0 0 0;
}

.form-group.added {
  animation: flash 2s;
}

@keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #ffc107;
  }
  100% {
    background-color: transparent;
  }
}