.add-item {
  display: block;
  margin-left: auto;
  margin-bottom: 15px;
}

input::placeholder {
  color: rgb(170, 170, 170) !important;
}

.fileDownload {
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  transition: all 0.3s;
  cursor: pointer;
}

.fileDownload:hover {
  background-color: rgba(50, 50, 50, 0.2);
}
