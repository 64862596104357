@import "~bootstrap/scss/bootstrap";
//@import "./components/navbar";
// @import "./components/editpage";
@import "./components/datatables";
// @import "./components/translationtable";
// @import "./components/bigcard";
@import "./components/global";
// @import "./components/page";
//@import "./components/sidebar";
// @import "./components/login";
@import "./components/translations"