html {
  scroll-behavior: smooth;
}

// body {
//   // font-family: "Roboto", sans-serif;
//   background: #fafafa;
//   color: #212832;
// }

// body p {
//   color: #69707a;
// }

.chartz {
  margin: auto;
}

.btn-circle {
  border-radius: 50%;
}

.btn-exclusive {
  border-radius: 50%;
  padding: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  // transition: all 0.3s;
}
.feather-16 {
  width: 16px;
  height: 16px;
}

.feather-20 {
  width: 20px;
  height: 20px;
}


.title-tiny  {
  margin-bottom: 1.2rem;
}

.pageLoading {
  background-color: white;
  opacity: 1;
  z-index: 3000;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1s;
}

// .switch-wrapper {
//   position: relative;
// }

// .switch-wrapper > div {
//   position: absolute;
// }

.resetform {
  label {
    width: 200px;
  }
}

.dropzone {
  p {
    color: inherit;
  }
}