$blue: #0586D7;
$green: #48C864;
$red: #FF6767;
$yellow: #FFC75B;
$gray: #838383;
$graylight: #757575;

.custom_modal.success {
  .modal-header {
    background-color: $green;
    svg {
      color: $green;
    }
  }

  .modal-body {
    hr {
      border-top: 1px solid rgba($green, 0.5);
    }

    button {
      color: $green;
    }

    button:active {
      border: 1px solid $green;
      color: $green !important;
      border-color: $green;
    }
  }

  .custom_modal__options {

    .line_divisoria {
      background-color: rgba($green, 0.5);
    }
  }
}

.custom_modal.warning {
  .modal-header {
    background-color: $yellow;
    svg {
      color: $yellow;
    }
  }

  .modal-body {
    hr {
      border-top: 1px solid rgba($yellow, 0.5);
    }

    button {
      color: $yellow;
    }

    button:active {
      border: 1px solid $yellow;
      color: $yellow !important;
      border-color: $yellow;
    }
  }

  .custom_modal__options {

    .line_divisoria {
      background-color: rgba($yellow, 0.5);
    }
  }
}

.custom_modal.error {
  .modal-header {
    background-color: $red;
    svg {
      color: $red;
    }
  }

  .modal-body {
    hr {
      border-top: 1px solid rgba($red, 0.5);
    }

    button {
      color: $red;
    }

    button:active {
      border: 1px solid $red;
      color: $red !important;
      border-color: $red;
    }
  }

  .custom_modal__options {

    .line_divisoria {
      background-color: rgba($red, 0.5);
    }
  }
}

.custom_modal .modal-content {
  border: none;
}
.custom_modal .modal-header {
  color: white;
  justify-content: flex-start;
  align-items: center;

  h1 {
    margin: 0;
  }

  svg {
    background-color: white;
    border-radius: 50%;
    padding: 0.25rem;
    margin-right: 1rem;
  }
}

.custom_modal .modal-body {

  p {
    color: $gray;
    font-weight: 500;
  }

  hr {
    width: 80%;
  }

  button {
    background-color: transparent;
    border: none;
    display: block;
    margin: auto;
  }

  button:active {
    background-color: transparent !important;
  }
}

.custom_modal .custom_modal__options {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .line_divisoria {
    width: 1px;
    height: 30px;
  }
}

.gray {
  color: $gray !important;
}