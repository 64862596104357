#header {
  text-align: right;
  color: white;
}

.page_header {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page_header__back {
  button {
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    align-items: center;
  }
}

.page_header h1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}

.page_header__icon {
  color: white;
  font-size: 0.75em;
  margin-left: 0.5rem;
  display: inline-flex;
}

.page_header p {
  font-size: 1.1rem;
  color: white;
  margin-bottom: 0;
}

@media screen and(max-width: 992px) {
  .page_header p{
    display: none;
  }
}
